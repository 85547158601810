import Http from 'utils/Http';

const apiEndPoints = {
  getAccommodationDetails: 'sfa_act.v1.act.get_accommodation',
  getTransportDetails: 'sfa_act.v1.act.get_transport',
  getVerificationDetails: 'sfa_act.v1.act.get_verification_status',
  uploadSupportFormData: 'sfa_act.v1.act.support',
};

export async function getAccommodationDetails(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getAccommodationDetails}?user_id=${params.user_id}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getTransportDetails(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getTransportDetails}?user_id=${params.user_id}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getVerificationDetails(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getVerificationDetails}?user_id=${params.user_id}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function uploadSupportFormData({ department, description, photo, contact }) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.uploadSupportFormData}`,
      method: 'POST',
      options: {
        department,
        description,
        photo,
        contact,
      },
    });
    return res;
  } catch (error) {
    throw error.message;
  }
}
