import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Media({ header, content, icon, border, otherContent, iconColor, setCenter, lat, lng }) {
  return (
    <div
      className={`flex gap-4 items-center pt-4 ${border ? 'border-b pb-4' : ''}`}
      onClick={() =>
        setCenter
          ? setCenter({
              lat: lat,
              lng: lng,
            })
          : null
      }
    >
      <div data-cy="content_icon" className="flex justify-center w-6">
        {icon.icon ? (
          <FontAwesomeIcon
            icon={icon}
            className={
              iconColor ? `fa-lg text-${iconColor} w-7 h-6` : 'fa-lg text-deep-gray w-7 h-6'
            }
          />
        ) : (
          <img src={icon} alt="icon image" />
        )}
      </div>
      <div>
        <p data-cy="header" className="text-xs text-deep-gray">
          {header}
        </p>
        <div className="flex">
          <p data-cy="content" className="text-small font-bold text-dark-gray">
            {content}
          </p>
          {otherContent ? (
            <p className="ml-2 text-xs font-bold text-dark-gray">{otherContent}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Media;
