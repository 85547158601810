const MODULE_NAME = 'act';

function generateKeyName(value) {
  return [MODULE_NAME, value];
}

const QUERY_KEYS = {
  GET_ACCOMMODATION_DETAILS: generateKeyName('accommodation-details'),
  GET_TRANSPORT_DETAILS: generateKeyName('transport-details'),
  GET_VERIFICATION_DETAILS: generateKeyName('verification-details'),
};

export default QUERY_KEYS;
