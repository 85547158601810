import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Media from './Media';
import {
  faBed,
  faBuilding,
  faChevronCircleDown,
  faChevronCircleRight,
  faMapMarkerAlt,
  faPhoneAlt,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
// import Pad from '../../assets/img/pad.svg';
import CheckIn from '../../assets/img/check_in.svg';
import { getDateInDDMMYYYY, getTimeInHHMM } from '../../constants/DateFunctions';
import CheckOut from '../../assets/img/check_out.svg';
import TransportIcon from '../../assets/img/transport_icon.svg';

CardOpener.propTypes = {
  accommodation: PropTypes.object,
  transport: PropTypes.object,
};

function CardOpener({ accommodation, transport, setCenter }) {
  const [cardOpenState, setCardOpenState] = React.useState(false);
  const panelRef = useRef();
  useEffect(() => {
    if (cardOpenState) {
      panelRef.current.style.maxHeight = panelRef.current.scrollHeight + 'px';
    } else {
      panelRef.current.style.maxHeight = 0;
    }
  }, [cardOpenState]);

  return (
    <div className="card-opener px-6">
      <div
        data-cy="card_opener"
        className="card-open-trigger"
        onClick={() => {
          setCardOpenState(!cardOpenState);
        }}
      >
        {accommodation ? (
          <Media
            setCenter={setCenter}
            lat={Number(accommodation?.coordinates?.split(',')[0])}
            lng={Number(accommodation?.coordinates?.split(',')[1])}
            content={
              new Intl.DateTimeFormat(['en-GB']).format(new Date(accommodation.from)) +
              ' to ' +
              new Intl.DateTimeFormat(['en-GB']).format(new Date(accommodation.to)) +
              ' - ' +
              accommodation.venue
            }
            icon={cardOpenState ? faChevronCircleDown : faChevronCircleRight}
            iconColor={'light-orange'}
            border={true}
          />
        ) : transport ? (
          <Media
            content={
              new Intl.DateTimeFormat(['en-GB']).format(new Date(transport.from)) +
              ' to ' +
              new Intl.DateTimeFormat(['en-GB']).format(new Date(transport.to))
            }
            icon={cardOpenState ? faChevronCircleDown : faChevronCircleRight}
            iconColor={'light-orange'}
            border={true}
          />
        ) : null}
      </div>
      <div
        data-cy="card_panel"
        className="card-panel overflow-hidden transition-all"
        ref={panelRef}
      >
        {accommodation ? (
          <>
            <div className="bg-white rounded-lg pb-4 px-6">
              <Media icon={faBuilding} header="Venue" content={accommodation.venue} border={true} />
              <Media
                icon={faMapMarkerAlt}
                header="Address"
                border={true}
                content={accommodation.address}
              />
              <Media
                icon={faPhoneAlt}
                header="Contact"
                border={true}
                content={accommodation.contact}
              />
              <Media
                icon={faBed}
                header="Occupancy Type"
                border={true}
                content={accommodation.occupancy_type}
              />
              {/* <Media icon={Pad} header="Room Number" content={accommodation.room_number} /> */}
            </div>
            <div className=" max-w-full bg-white  font-roboto rounded-lg px-6 pb-4">
              {accommodation.checkin ? (
                <Media
                  icon={CheckIn}
                  header="Check-In"
                  content={getTimeInHHMM(accommodation.checkin)}
                  otherContent={getDateInDDMMYYYY(accommodation.checkin)}
                  border={true}
                />
              ) : null}
              {accommodation.checkout ? (
                <Media
                  icon={CheckOut}
                  header="Check-Out"
                  content={getTimeInHHMM(accommodation.checkout)}
                  otherContent={getDateInDDMMYYYY(accommodation.checkout)}
                />
              ) : null}
            </div>
          </>
        ) : transport ? (
          <div className="mt-6 max-w-full bg-white px-6 py-4  font-roboto rounded-lg">
            <div className="flex items-center pb-4 border-b border-fade-gray  gap-4">
              <img src={TransportIcon} alt="transport-icon" />
              <div>
                <p className="text-xs text-deep-gray">Vehcile Number</p>
                <p data-cy="vehicle_number" className="text-small font-bold text-dark-gray">
                  {transport.vehicle_number}
                </p>
                {transport.vehicle_type ? (
                  <button data-cy="vehicle_type" className="text-xxs blueBadge font-bold mt-1">
                    {transport.vehicle_type}
                  </button>
                ) : null}
              </div>
            </div>
            <div className="pb-4 border-b border-fade-gray">
              <Media icon={faUser} header="Contact Person" content={transport.contact_person} />
              <Media icon={faPhoneAlt} header="Contact" content={transport.contact} />
              {/* <Media icon={faMapMarkerAlt} header="Pickup Point" content={transport.} /> */}
            </div>
            <div className="text-center">
              <p data-cy="transport_remark" className="text-xs text-deep-gray pt-4">
                {transport.remark}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default CardOpener;
