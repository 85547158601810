import React, { useEffect, useState } from 'react';
import ProfileBar from '../profiles/Components/ProfileBar';
import Media from './components/Media';
import {
  faBuilding,
  faMapMarkerAlt,
  faPhoneAlt,
  faBed,
  faBus,
} from '@fortawesome/free-solid-svg-icons';
import NavigateCard from './components/NavigateCard';
import CheckIn from '../assets/img/check_in.svg';
import CheckOut from '../assets/img/check_out.svg';
import Pad from '../assets/img/pad.svg';
import { useUserProfile } from '../profiles/queries/profileHooks';
import { useAccommodationDetails } from './queries/actHooks';
import Loader from '../components/Loader';
import { getDateInDDMMYYYY, getTimeInHHMM } from '../constants/DateFunctions';
// import { GoogleMap, LoadScript } from '@react-google-maps/api';
import CardOpener from './components/CardOpener';

export default function Accommodation() {
  const { data: profile } = useUserProfile();
  const { data, isLoading } = useAccommodationDetails({ user_id: profile.message.sfa_id });
  const [center, setCenter] = useState({});
  const accommodations = data;
  // const mapStyles = {
  //   height: '30vh',
  //   width: '100%',
  // };

  console.log(center);

  useEffect(() => {
    setCenter({
      lat: accommodations && Number(accommodations[0]?.coordinates?.split(',')[0]),
      lng: accommodations && Number(accommodations[0]?.coordinates?.split(',')[1]),
    });
  }, []);
  console.log(data);
  return (
    <div>
      <ProfileBar head="Accommodation" act={true} />
      <div className="mx-6 mb-5 flex justify-between flex-col gap-6 h-full">
        <div className="mt-6 max-w-full bg-white  font-roboto rounded-lg">
          <div className="w-full">
            {/* <LoadScript
              className="w-full h-30"
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            >
              <GoogleMap mapContainerStyle={mapStyles} zoom={13} center={center} />
            </LoadScript> */}
          </div>
          {Array.isArray(accommodations) ? (
            <div>
              {isLoading ? (
                <Loader />
              ) : accommodations.length === 0 ? (
                <div
                  data-cy="accommodation_empty_msg"
                  className="p-20 text-center text-l font-bold"
                >
                  Accommodations is not alloted.
                </div>
              ) : (
                <>
                  {accommodations.length === 1 ? (
                    <>
                      <div className="bg-white rounded-lg pb-4 px-6">
                        <Media
                          icon={faBuilding}
                          header="Venue"
                          content={accommodations[0].venue}
                          border={true}
                        />
                        <Media
                          icon={CheckIn}
                          header="Date"
                          content={
                            new Intl.DateTimeFormat(['en-GB']).format(
                              new Date(accommodations[0].from)
                            ) +
                            ' to ' +
                            new Intl.DateTimeFormat(['en-GB']).format(
                              new Date(accommodations[0].to)
                            )
                          }
                          border={true}
                        />
                        <Media
                          icon={faMapMarkerAlt}
                          header="Address"
                          border={true}
                          content={accommodations[0].address}
                        />
                        <Media
                          icon={faPhoneAlt}
                          header="Contact"
                          border={true}
                          content={accommodations[0].contact}
                        />
                        <Media
                          icon={faBed}
                          header="Occupancy Type"
                          border={true}
                          content={accommodations[0].occupancy_type}
                        />
                        <Media
                          icon={Pad}
                          header="Room Number"
                          content={accommodations[0].room_number}
                        />
                      </div>
                      <div className=" max-w-full bg-white  font-roboto rounded-lg px-6 pb-4">
                        {accommodations[0].checkin ? (
                          <Media
                            icon={CheckIn}
                            header="Check-In"
                            content={getTimeInHHMM(accommodations[0].checkin)}
                            otherContent={getDateInDDMMYYYY(accommodations[0].checkin)}
                            border={true}
                          />
                        ) : null}
                        {accommodations[0].checkout ? (
                          <Media
                            icon={CheckOut}
                            header="Check-Out"
                            content={getTimeInHHMM(accommodations[0].checkout)}
                            otherContent={getDateInDDMMYYYY(accommodations[0].checkout)}
                          />
                        ) : null}
                      </div>
                    </>
                  ) : (
                    accommodations.map((accommodation, i) => (
                      <CardOpener accommodation={accommodation} key={i} setCenter={setCenter} />
                    ))
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="py-10 w-full text-gray-700 font-md text-center">
              {accommodations?.Error || 'Attendee not Found'}
            </div>
          )}
        </div>
        <NavigateCard
          dataCy="navigate"
          icon={faBus}
          header="Transport"
          navigateTo="/act/transport"
        />
      </div>
    </div>
  );
}
