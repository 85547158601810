import { useQuery } from 'react-query';
import QUERY_KEYS from './queryKeys';
import { getAccommodationDetails, getTransportDetails, getVerificationDetails } from '../services';

export const useAccommodationDetails = (params) => {
  return useQuery(QUERY_KEYS.GET_ACCOMMODATION_DETAILS, () => getAccommodationDetails(params), {
    retry: false,
  });
};

export const useTransportDetails = (params) => {
  return useQuery(QUERY_KEYS.GET_TRANSPORT_DETAILS, () => getTransportDetails(params), {
    retry: false,
  });
};

export const useVerificationDetails = (params) => {
  return useQuery(QUERY_KEYS.GET_VERIFICATION_DETAILS, () => getVerificationDetails(params), {
    retry: false,
  });
};
